import { useTranslation } from 'react-i18next'

import { Button } from '@/shared/ui/button'
import { useEventId } from '@/shared/useEventId'

interface Props {
    onSkip: () => void
}

export const SkipButton = ({ onSkip }: Props) => {
    const eventId = useEventId()
    const { t } = useTranslation()

    if (!eventId.includes('alchemic')) return null

    return (
        <Button
            variant='ghost'
            className='w-fit bg-primary-foreground text-primary hover:bg-primary/90'
            onClick={onSkip}
        >
            {t('skip')}
        </Button>
    )
}
