import { SkipButton } from '@/pages/form/components/SkipButton'
import { BaseFormStepProps } from '@/pages/form/types'
import { useEventFeedbackAnswer } from '@/pages/form/useEventFeedback.ts'
import { OptionsSlider } from '@/shared/ui/options-slider/options-slider.tsx'
import { OptionsSliderQuestion, SliderOption } from '@monorepo/functions/src/types/feedback'

export const OptionsSliderFormStep = ({ question, onNextStep }: BaseFormStepProps<OptionsSliderQuestion>) => {
    const { answer, setAnswer } = useEventFeedbackAnswer(question)

    const handleValueCommit = (commmittedOption: SliderOption) => {
        setAnswer({
            type: 'OPTIONS_SLIDER',
            value: commmittedOption.value,
            departments: question.departments,
            categories: question.categories,
        })

        onNextStep()
    }

    return (
        <div className='flex flex-col items-center space-y-6'>
            <div className='space-y-2 text-center'>
                <h2 className='text-xl font-medium text-primary'>{question.title}</h2>
                {question.explanation && <p className='text-sm text-primary'>{question.explanation}</p>}
            </div>
            <div className='w-80 space-y-4'>
                <OptionsSlider options={question.options} value={answer?.value} onChangeCommitted={handleValueCommit} />
            </div>
            <div className='mt-2 flex justify-end gap-2'>
                <SkipButton onSkip={onNextStep} />
            </div>
        </div>
    )
}
