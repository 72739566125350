import { Category, Department, TextFeedback } from '@monorepo/functions/src/types/feedback'

export const getCategoryCount = (textFeedbacks: TextFeedback[], category: Category) =>
    textFeedbacks.filter(feedback => feedback.answer.categories.includes(category)).length

export const getDepartmentCount = (textFeedbacks: TextFeedback[], department: Department) =>
    textFeedbacks.filter(feedback => feedback.answer.departments.includes(department)).length

export const getDepartmentCountForCategory = (
    textFeedbacks: TextFeedback[],
    category: Category,
    department: Department,
) =>
    textFeedbacks.filter(
        comment => comment.answer.categories.includes(category) && comment.answer.departments.includes(department),
    ).length
