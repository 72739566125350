import { FirebaseError } from 'firebase/app'
import {
    FirestoreDataConverter,
    QuerySnapshot,
    collection,
    doc,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    where,
} from 'firebase/firestore'

import { firestore } from '@/shared/firebase/firebase'
import { Chat } from '@monorepo/functions/src/types/chat'
import { Event } from '@monorepo/functions/src/types/event'

const getChatsCollection = (eventId: string) => collection(firestore, `events/${eventId}/chats`)

const getEventDocument = (eventId: string) => doc(firestore, `events/${eventId}`).withConverter(eventConverter)

const eventConverter: FirestoreDataConverter<Event> = {
    toFirestore: event => event,
    fromFirestore: snapshot => snapshot.data() as Event,
}

const isEventChatEnabledGuard = async (eventId: string) => {
    const event = await getDoc(getEventDocument(eventId))

    if (!event.data()?.isChatEnabled) throw new FirebaseError('failed-precondition', 'Chat not enabled for this event')
}

export async function getActiveChat(uid: string, eventId: string) {
    await isEventChatEnabledGuard(eventId)

    const chats = (await getDocs(
        query(getChatsCollection(eventId), where('uid', '==', uid), orderBy('createdAt', 'desc'), limit(1)),
    )) as QuerySnapshot<Chat>

    if (chats.empty) return null
    else return { ...chats.docs[0].data(), id: chats.docs[0].id } as Chat
}
