import { useTranslation } from 'react-i18next'

import { motion } from 'framer-motion'

import { SkipButton } from '@/pages/form/components/SkipButton'
import { BaseFormStepProps } from '@/pages/form/types'
import { useEventFeedbackAnswer } from '@/pages/form/useEventFeedback.ts'
import { Button } from '@/shared/ui/button.tsx'
import { Checkbox } from '@/shared/ui/checkbox.tsx'
import { Input } from '@/shared/ui/input'
import { Label } from '@/shared/ui/label.tsx'
import { MultiSelectQuestion } from '@monorepo/functions/src/types/feedback'
import { CheckedState } from '@radix-ui/react-checkbox'

const buttonVariants = {
    initial: { scale: 1, opacity: 0, x: -20 },
    pressed: { scale: 0.95 },
}

const FormButton = motion(Button)

export const MultiSelectFormStep = ({ question, onNextStep }: BaseFormStepProps<MultiSelectQuestion>) => {
    const { t } = useTranslation()
    const { setAnswer, answer } = useEventFeedbackAnswer(question)

    const additionalField =
        question.additionalFields?.find(({ triggeredBy }) => answer?.value.includes(triggeredBy)) ?? null

    const handleSelectOption = (checked: CheckedState, optionId: string) => {
        const currentAnswerValue = answer?.value ?? []

        const value = checked
            ? [...currentAnswerValue, optionId]
            : [...currentAnswerValue.filter(value => value !== optionId)]

        const selectedAdditionalField =
            question.additionalFields?.find(({ triggeredBy }) => value.includes(triggeredBy)) ?? null

        setAnswer({
            type: 'MULTI_SELECT',
            value,
            departments: question.departments,
            categories: question.categories,
            ...(selectedAdditionalField && answer?.additionalFieldValue
                ? { additionalFieldValue: answer.additionalFieldValue }
                : {}),
        })
    }

    const handleAdditionalFieldValueChange = (newValue: string) => {
        setAnswer({
            type: 'MULTI_SELECT',
            value: answer!.value,
            departments: question.departments,
            categories: question.categories,
            additionalFieldValue: newValue,
        })
    }

    return (
        <>
            <h2 className='text-center text-xl font-bold'>
                {question.title}
                <span className='block font-light italic'>{t('form.multiSelect.selectAll')}</span>
            </h2>
            <div className='mt-6 flex w-fit flex-col justify-center justify-self-center'>
                {question.options.map(option => (
                    <div key={option.value} className='mb-2 flex items-center'>
                        <Checkbox
                            id={option.value}
                            checked={answer?.value.includes(option.value)}
                            onCheckedChange={checked => handleSelectOption(checked, option.value)}
                        />
                        <Label className='ml-2 text-lg' htmlFor={option.value}>
                            {option.label}
                        </Label>
                    </div>
                ))}
                {additionalField && (
                    <Input
                        placeholder={additionalField.label}
                        value={answer?.additionalFieldValue ?? ''}
                        onChange={e => handleAdditionalFieldValueChange(e.target.value)}
                        className='my-1 w-full'
                    />
                )}
                <div className='mt-2 flex justify-end gap-2'>
                    <SkipButton onSkip={onNextStep} />
                    {answer && (
                        <FormButton
                            type='button'
                            variants={buttonVariants}
                            whileTap='pressed'
                            size='default'
                            onClick={onNextStep}
                            initial='initial'
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5 }}
                            className='w-fit'
                        >
                            {t('form.common.next')}
                        </FormButton>
                    )}
                </div>
            </div>
        </>
    )
}
