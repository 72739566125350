import { memo, useEffect, useState } from 'react'

import { FirebaseError } from 'firebase/app'

import { startEventChat } from '@/shared/api/clients'
import { useUser } from '@/shared/auth'
import { useEventId } from '@/shared/useEventId'
import { Chat } from '@monorepo/functions/src/types/chat'

import { getActiveChat } from '../api/getActiveChat'
import { ActiveChat } from './ActiveChat'
import { ErrorChat } from './ErrorChat'
import { LoadingChat } from './LoadingChat'

const useChat = (uid: string, eventId: string) => {
    const [chat, setChat] = useState<Chat | null>(null)
    const [error, setError] = useState<string | null>(null)
    const [isLoading, setIsLoadingChat] = useState(true)

    useEffect(() => {
        const getOrStartChat = async () => {
            setIsLoadingChat(true)

            try {
                const activeChat = await getActiveChat(uid, eventId)
                if (activeChat) setChat(activeChat)
                else {
                    await startEventChat({ eventId })
                    setChat(await getActiveChat(uid, eventId))
                }
            } catch (error) {
                console.error(error)
                if (error instanceof FirebaseError && error.code.includes('failed-precondition'))
                    setError('Este evento no tiene habilitado el asistente personal')
                else setError('Ha habido un error al cargar tu asistente personal')
            } finally {
                setIsLoadingChat(false)
            }
        }

        if (eventId && uid) getOrStartChat()
    }, [uid, eventId])

    return { chat, isLoading, error }
}

export const AIChat = memo(() => {
    const user = useUser()
    const eventId = useEventId()
    const { chat, isLoading, error } = useChat(user.uid, eventId)

    if (isLoading) return <LoadingChat />

    if (error) return <ErrorChat errorMessage={error} />

    return chat && <ActiveChat id={chat.id} />
})
