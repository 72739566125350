import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import { ErrorPage } from '@/pages/error/ErrorPage.tsx'
import { TrackingService } from '@/shared/lib/tracking/index.ts'
import * as Sentry from '@sentry/react'

import '../i18n/i18n.ts'
import '../marked.ts'
import { RoutingComponent } from '../navigation/RoutingComponent.tsx'
import { UnauthenticatedProviders } from '../providers.tsx'
import '../styles/index.css'
import '../versions/versionListener.ts'

TrackingService.init()

ReactDOM.createRoot(document.getElementById('root')!).render(
    <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog>
        <UnauthenticatedProviders>
            <BrowserRouter>
                <RoutingComponent />
            </BrowserRouter>
        </UnauthenticatedProviders>
    </Sentry.ErrorBoundary>,
)
