import { useEffect, useState } from 'react'

import { Send, Sparkles } from 'lucide-react'
import { marked } from 'marked'

import { sendEventChatMessage } from '@/pages/dashboard/features/aiChat/api/sendEventChatMessage'
import { useScrollToBottom } from '@/shared/lib/hooks/useScrollToBottom'
import { Badge } from '@/shared/ui/badge'
import { Button } from '@/shared/ui/button'
import { useEventId } from '@/shared/useEventId'
import { ChatMessage } from '@monorepo/functions/src/types/chat'

import { getChatMessages } from '../api/getChatMessages'

type SimplifedMessage = Omit<ChatMessage, 'id' | 'createdAt'>

interface Props {
    id: string
}

export const ActiveChat = ({ id }: Props) => {
    const eventId = useEventId()
    const [messages, setMessages] = useState<SimplifedMessage[]>([])
    const [input, setInput] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [streamingMessage, setStreamingMessage] = useState('')
    const { ref, scrollToBottom } = useScrollToBottom()

    useEffect(() => {
        const getExistingMessages = async () => {
            const chats = await getChatMessages(eventId, id)
            setMessages(chats)
        }

        if (eventId && id) getExistingMessages()
    }, [eventId, id])

    useEffect(() => {
        if (messages.length > 0) scrollToBottom()
    }, [messages])

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        if (!input.trim()) return

        const userMessage: SimplifedMessage = { role: 'user', content: input }
        setMessages(prev => [...prev, userMessage])
        setInput('')
        setIsLoading(true)
        setStreamingMessage('')

        try {
            await sendEventChatMessage(eventId, input, id, chunk => {
                setStreamingMessage(previousMessageStreamed => {
                    if (chunk !== '[DONE]') return previousMessageStreamed + chunk

                    setMessages(prev => [...prev, { role: 'assistant', content: previousMessageStreamed }])
                    return previousMessageStreamed
                })
            })
        } catch (error) {
            console.error('Error getting AI response:', error)
            const errorMessage: SimplifedMessage = {
                role: 'assistant',
                content: 'Lo siento, hubo un error al procesar tu pregunta. Por favor, inténtalo de nuevo.',
            }
            setMessages(prev => [...prev, errorMessage])
        } finally {
            setIsLoading(false)
            setStreamingMessage('')
        }
    }

    return (
        <div className='flex h-full flex-1 flex-col rounded-lg bg-gray-800 bg-opacity-50 p-4'>
            <div className='mb-4 flex items-center justify-between'>
                <div className='flex items-center gap-2'>
                    <div>
                        <div className='flex w-full items-center justify-between'>
                            <h3 className='text-xl font-semibold text-white'>Asistente personal inteligente</h3>
                            <Badge variant='secondary' className='relative bg-gray-800'>
                                <p>Beta</p>
                                <Sparkles className='ml-1 h-3 w-3' />
                            </Badge>
                        </div>
                        <p className='text-sm text-gray-400'>
                            Pregunta lo que quieras sobre las respuestas tus asistentes.
                        </p>
                    </div>
                </div>
            </div>

            <div ref={ref} className='mb-4 flex-1 space-y-4 overflow-y-auto'>
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={`rounded-lg p-3 ${
                            message.role === 'user'
                                ? 'ml-auto max-w-[80%] bg-blue-600'
                                : 'mr-auto max-w-[80%] bg-gray-700'
                        }`}
                    >
                        <div dangerouslySetInnerHTML={{ __html: marked(message.content) }} />
                    </div>
                ))}
                {streamingMessage && (
                    <div className='mr-auto max-w-[80%] rounded-lg bg-gray-700 p-3'>
                        <div dangerouslySetInnerHTML={{ __html: marked(streamingMessage) }} />
                    </div>
                )}
                {isLoading && !streamingMessage && (
                    <div className='mr-auto rounded-lg bg-gray-700 p-3'>Pensando...</div>
                )}
            </div>

            <form onSubmit={handleSubmit} className='flex gap-2'>
                <input
                    type='text'
                    value={input}
                    onChange={e => setInput(e.target.value)}
                    placeholder='Pregunta sobre el feedback...'
                    className='flex-1 rounded-lg bg-gray-800 px-4 py-2 text-white focus:outline-none focus:ring-2 focus:ring-blue-500'
                />
                <Button type='submit' disabled={isLoading || !input.trim()} className='bg-blue-600 hover:bg-blue-700'>
                    <Send className='h-5 w-5' />
                </Button>
            </form>
        </div>
    )
}
