import { motion } from 'framer-motion'

import medioYMedioLogo from '@/pages/form/assets/img/medio-y-medio-logo.avif'
import { type EventFormOnboardingProps } from '@/pages/form/types.ts'

export const FormOnboarding: React.FC<EventFormOnboardingProps> = ({ onStartForm }) => (
    <>
        <div className='z-10 flex flex-col items-center justify-start'>
            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                className='s-64 m-12 max-w-md'
            >
                <img src={medioYMedioLogo} alt='Medio y Medio Festival' className='mx-auto w-full' />
            </motion.div>

            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3, duration: 0.5 }}
                className='z-10 mx-4 mb-10 w-full max-w-lg text-center'
            >
                <h1 className='mb-6 text-4xl font-bold tracking-tight text-foreground md:text-5xl'>
                    ¡Tu opinión nos importa!
                </h1>
                <p className='text-lg text-muted-foreground md:text-xl'>
                    Ayúdanos a hacer que Medio y Medio sea cada vez mejor.
                </p>
            </motion.div>

            <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.6, duration: 0.5 }}
                className='mb-8 flex flex-col items-center gap-6'
            >
                <div className='flex flex-col gap-4 text-center text-muted-foreground'>
                    <p className='text-md'>✨ Solo te tomará un minuto ✨</p>
                </div>

                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={onStartForm}
                    className='mt-4 transform bg-primary px-10 py-4 text-lg font-bold text-primary-foreground transition duration-300 ease-in-out hover:bg-accent/90'
                >
                    Empezar
                </motion.button>
            </motion.div>
        </div>
    </>
)
