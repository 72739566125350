import { useTranslation } from 'react-i18next'

import { motion } from 'framer-motion'

import { useAuth } from '@/shared/auth'
import { useEventId } from '@/shared/useEventId'

import { EventFormOnboardingProps } from '../types'

export const FormOnboarding: React.FC<EventFormOnboardingProps> = ({ onStartForm }) => {
    const { signOut } = useAuth()
    const { t } = useTranslation()
    const eventId = useEventId()

    if (!eventId.includes('practitioner')) {
        onStartForm()
        return null
    }

    const handleStartForm = async () => {
        if (!eventId.includes('-post')) {
            await signOut()
        }
        onStartForm() // Anonymous user will be automatically created on the next page
    }

    return (
        <>
            <div className='z-10 flex flex-col items-center justify-center'>
                <motion.h1
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.2 }}
                    className='mb-8 text-center text-3xl font-medium tracking-wide text-primary'
                >
                    {t('alchemic.form.onboarding.title')}
                </motion.h1>

                <motion.button
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2, duration: 0.2 }}
                    onClick={handleStartForm}
                    className='mb-8 transform bg-primary px-8 py-3 text-primary-foreground transition duration-300 ease-in-out hover:scale-105 hover:opacity-90'
                >
                    {t('alchemic.form.onboarding.startButton')}
                </motion.button>
            </div>
        </>
    )
}
