import { useState } from 'react'
import { Link } from 'react-router-dom'

import {
    Calendar,
    Check,
    ChevronRight,
    Clock,
    Edit2,
    MessageCircle,
    MessageSquare,
    SlidersHorizontal,
    Star,
    ToggleLeft,
    Users,
    X,
} from 'lucide-react'

import { updateEventName } from '@/shared/api/clients'
import { Button } from '@/shared/ui/button'
import { Input } from '@/shared/ui/input'
import { EventDetails } from '@monorepo/functions/src/types/GetUserEvents'

interface EventCardProps {
    event: EventDetails
    onEventUpdate: (updatedEvent: EventDetails) => void
}

const ICON_COLORS = {
    totalFeedbacks: 'text-blue-400',
    totalPeople: 'text-indigo-400',
    rating: 'text-yellow-400',
    slider: 'text-green-400',
    text: 'text-purple-400',
    yesNo: 'text-pink-400',
    timestamp: 'text-cyan-400',
} as const

const StatItem = ({
    icon: Icon,
    value,
    label,
    color,
}: {
    icon: any
    value: number | string
    label?: string
    color: string
}) => (
    <div className='flex items-center gap-2 text-sm'>
        <Icon className={`h-4 w-4 ${color}`} />
        <span className='text-white'>
            {value} {label}
        </span>
    </div>
)

const TimeStamp = ({ icon: Icon, label, date }: { icon: any; label: string; date: string }) => (
    <div className='flex items-center gap-2 text-sm'>
        <Icon className={`h-4 w-4 ${ICON_COLORS.timestamp}`} />
        <span className='text-gray-400'>{label}</span>
        <span className='text-white'>{new Date(date).toLocaleDateString()}</span>
    </div>
)

export const EventCard = ({ event, onEventUpdate }: EventCardProps) => {
    const { id, name, description, summary, createdAt } = event
    const [isEditing, setIsEditing] = useState(false)
    const [newName, setNewName] = useState(name)
    const [isUpdating, setIsUpdating] = useState(false)

    const handleEditClick = (e: React.MouseEvent) => {
        e.preventDefault()
        setIsEditing(true)
    }

    const handleCancelEdit = (e: React.MouseEvent) => {
        e.preventDefault()
        setIsEditing(false)
        setNewName(name)
    }

    const saveEdit = async () => {
        if (newName.trim() === '') return

        setIsUpdating(true)
        try {
            await updateEventName({
                eventId: id,
                name: newName.trim(),
            })
            setIsEditing(false)
            onEventUpdate({
                ...event,
                name: newName.trim(),
            })
        } catch (error) {
            console.error('Error updating event name:', error)
        } finally {
            setIsUpdating(false)
        }
    }

    const handleSaveEdit = async (e: React.MouseEvent) => {
        e.preventDefault()
        saveEdit()
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            saveEdit()
        }
    }

    return (
        <Link
            to={`/${id}/dashboard`}
            className='group flex flex-col rounded-lg border border-gray-700 bg-gray-800 p-6 text-left transition-all duration-200 hover:border-blue-500 hover:bg-gray-700'
        >
            <div className='flex items-center justify-between'>
                {isEditing ? (
                    <div className='flex items-center gap-2'>
                        <Input
                            value={newName}
                            onChange={e => setNewName(e.target.value)}
                            onClick={e => e.preventDefault()}
                            onKeyDown={handleKeyDown}
                            className='max-w-[200px] bg-gray-900'
                            autoFocus
                        />
                        <Button
                            size='icon'
                            variant='ghost'
                            onClick={handleSaveEdit}
                            disabled={isUpdating || newName.trim() === ''}
                            className='h-8 w-8 text-green-400 hover:text-green-500'
                        >
                            <Check className='h-4 w-4' />
                        </Button>
                        <Button
                            size='icon'
                            variant='ghost'
                            onClick={handleCancelEdit}
                            disabled={isUpdating}
                            className='h-8 w-8 text-red-400 hover:text-red-500'
                        >
                            <X className='h-4 w-4' />
                        </Button>
                    </div>
                ) : (
                    <>
                        <h2 className='text-2xl font-bold text-white'>{name}</h2>
                        <div className='flex items-center gap-2'>
                            <Button
                                size='icon'
                                variant='ghost'
                                onClick={handleEditClick}
                                className='h-8 w-8 text-gray-400 hover:text-blue-400'
                            >
                                <Edit2 className='h-4 w-4' />
                            </Button>
                            <ChevronRight className='h-5 w-5 transform text-gray-400 transition-transform group-hover:translate-x-1 group-hover:text-blue-400' />
                        </div>
                    </>
                )}
            </div>

            {description && <p className='mt-3 text-sm text-gray-400'>{description}</p>}

            {summary && (
                <div className='mt-4 space-y-3 border-t border-gray-700 pt-4'>
                    <div className='grid grid-cols-2 gap-3 sm:grid-cols-3'>
                        <StatItem icon={Users} value={summary.totalPeople} color={ICON_COLORS.totalPeople} />
                        <StatItem
                            icon={MessageSquare}
                            value={summary.totalFeedbacks}
                            color={ICON_COLORS.totalFeedbacks}
                        />
                        {summary.averageRating != null && (
                            <StatItem icon={Star} value={summary.averageRating.toFixed(1)} color={ICON_COLORS.rating} />
                        )}
                    </div>

                    <div className='grid grid-cols-2 gap-3 sm:grid-cols-2'>
                        <StatItem
                            icon={SlidersHorizontal}
                            value={summary.totalSliderResponses}
                            label='valoraciones'
                            color={ICON_COLORS.slider}
                        />
                        <StatItem
                            icon={MessageCircle}
                            value={summary.totalTextResponses}
                            label='textos'
                            color={ICON_COLORS.text}
                        />
                        <StatItem
                            icon={ToggleLeft}
                            value={summary.totalYesNoResponses}
                            label='sí/no'
                            color={ICON_COLORS.yesNo}
                        />
                    </div>

                    <div className='flex flex-col gap-2'>
                        {summary.lastFeedbackAt && (
                            <TimeStamp icon={Clock} label='Último feedback:' date={summary.lastFeedbackAt} />
                        )}
                        {createdAt && <TimeStamp icon={Calendar} label='Creado:' date={createdAt} />}
                    </div>
                </div>
            )}
        </Link>
    )
}
