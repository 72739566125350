import { httpsCallable } from 'firebase/functions'

import { functions } from '@/shared/firebase/firebase.ts'
import { GetUserEventsRequest, GetUserEventsResponse } from '@monorepo/functions/src/types/GetUserEvents'
import {
    NewFeedbackQuestionAnsweredRequest,
    NewFeedbackQuestionAnsweredResponse,
} from '@monorepo/functions/src/types/NewFeedbackQuestionAnswered'
import {
    SubscribeToMailerLiteRequest,
    SubscribeToMailerLiteResponse,
} from '@monorepo/functions/src/types/SubscribeToMailerLite'
import { UpdateEventNameRequest, UpdateEventNameResponse } from '@monorepo/functions/src/types/UpdateEventName'
import {
    GetEventChatAnswerRequest,
    StartEventChatRequest,
    StartEventChatResponse,
} from '@monorepo/functions/src/types/chat'

import { fetcher } from './fetcher'

const newFeedbackQuestionAnsweredCloudFn = httpsCallable<
    NewFeedbackQuestionAnsweredRequest,
    NewFeedbackQuestionAnsweredResponse
>(functions, 'newFeedbackQuestionAnswered')
export const newFeedbackQuestionAnswered = (req: NewFeedbackQuestionAnsweredRequest) => {
    // we do not await on purpose, as it is an async process
    newFeedbackQuestionAnsweredCloudFn(req)
}

export const subscribeToMailerLite = httpsCallable<SubscribeToMailerLiteRequest, SubscribeToMailerLiteResponse>(
    functions,
    'subscribeToMailerLite',
)

export const getUserEvents = httpsCallable<GetUserEventsRequest, GetUserEventsResponse>(functions, 'getUserEvents')

export const startEventChatCloudFn = httpsCallable<StartEventChatRequest, StartEventChatResponse>(
    functions,
    'startEventChat',
)
export const startEventChat = (req: StartEventChatRequest) => startEventChatCloudFn(req).then(res => res.data)

export const getEventChatAnswer = (request: GetEventChatAnswerRequest) =>
    fetcher({
        endpoint: '/getEventChatAnswer',
        method: 'POST',
        body: request,
    })

const updateEventNameFunction = httpsCallable<UpdateEventNameRequest, UpdateEventNameResponse>(
    functions,
    'updateEventName',
)

export const updateEventName = async (params: UpdateEventNameRequest): Promise<UpdateEventNameResponse> => {
    const result = await updateEventNameFunction(params)
    return result.data
}
