import { EventDetails } from '@monorepo/functions/src/types/GetUserEvents'

import { EventCard } from './EventCard'

interface EventsGridProps {
    events: EventDetails[]
    onEventUpdate: (updatedEvent: EventDetails) => void
}

export const EventsGrid = ({ events, onEventUpdate }: EventsGridProps) => {
    if (events.length === 0) {
        return (
            <div className='rounded-lg border border-gray-700 bg-gray-800 p-8 text-center'>
                <p className='text-lg text-gray-300'>No hay paneles disponibles</p>
                <p className='mt-2 text-sm text-gray-400'>
                    Contacta con tu administrador para obtener acceso a los paneles.
                </p>
            </div>
        )
    }

    return (
        <div className='grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3'>
            {events.map(event => (
                <EventCard key={event.id} event={event} onEventUpdate={onEventUpdate} />
            ))}
        </div>
    )
}
