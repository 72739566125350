import { Dispatch, SetStateAction } from 'react'

import { MessageSquareText } from 'lucide-react'

import { Category, Department, TextFeedback } from '@monorepo/functions/src/types/feedback'

import { departments } from '../model/constants'
import { getDepartmentCountForCategory } from '../model/feedbackCounts'
import { getCategoryColor } from './colors'
import { departmentIcons } from './icons'

interface Props {
    setSelectedDepartments: Dispatch<SetStateAction<Department[]>>
    setSelectedCategories: Dispatch<SetStateAction<Category[]>>
    textFeedbacks: TextFeedback[]
    selectedCategory: Category
}

export const DepartmentsSection = ({
    setSelectedDepartments,
    setSelectedCategories,
    textFeedbacks,
    selectedCategory,
}: Props) => {
    const getDepartmentCount = (department: Department) => {
        return getDepartmentCountForCategory(textFeedbacks, selectedCategory, department)
    }

    const sortedDepartments = [...departments].sort((a, b) => getDepartmentCount(b) - getDepartmentCount(a))

    const handleDepartmentBarClick = (dept: Department) => {
        setSelectedDepartments([dept])
        setSelectedCategories([selectedCategory])
    }

    return (
        <div className='mt-8'>
            <h3 className='mb-4 bg-gradient-to-r from-blue-400 to-cyan-600 bg-clip-text text-xl font-semibold text-transparent'>
                Departamentos
            </h3>
            <div className='space-y-2'>
                {sortedDepartments.map(dept => {
                    const count = getDepartmentCount(dept)
                    const maxCount = Math.max(...departments.map(d => getDepartmentCount(d)))
                    const width = maxCount > 0 ? (count / maxCount) * 100 : 0
                    return (
                        <button
                            key={dept}
                            className='w-full text-left transition-transform duration-200 hover:scale-[1.02]'
                            onClick={() => handleDepartmentBarClick(dept)}
                        >
                            <div className='flex items-center gap-2'>
                                <div className='h-4 w-full rounded-full bg-gray-700 shadow-inner'>
                                    <div
                                        className={`h-4 rounded-full bg-gradient-to-r transition-all duration-500 ease-in-out ${getCategoryColor(selectedCategory)}`}
                                        style={{ width: `${width}%` }}
                                    ></div>
                                </div>
                                <div className='flex flex-row gap-2'>
                                    <span className='flex w-24 items-center'>
                                        {departmentIcons[dept]}
                                        <span className='ml-2'>{dept}</span>
                                    </span>
                                    <span className='flex items-center'>
                                        <span
                                            className={`ml-2 bg-gradient-to-r bg-clip-text text-transparent ${getCategoryColor(selectedCategory)}`}
                                        >
                                            {count}
                                        </span>
                                        <MessageSquareText className='ml-2 h-4 w-4' />
                                    </span>
                                </div>
                            </div>
                        </button>
                    )
                })}
            </div>
        </div>
    )
}
