import { Dispatch, SetStateAction, useState } from 'react'

import { Bot, MessageSquare } from 'lucide-react'

import { Button } from '@/shared/ui/button'
import { Sidebar, SidebarContent, SidebarHeader } from '@/shared/ui/sidebar'
import { Category, Department, TextFeedback, WithCreatedAt } from '@monorepo/functions/src/types/feedback'

import { AIChat } from '../../features/aiChat/ui/AIChat'
import { CommentsSidebar } from './CommentsSidebar'

type TextFeedbackWithCreatedAt = WithCreatedAt<TextFeedback>

interface Props {
    selectedCategory: Category
    selectedDepartments: Department[]
    setSelectedDepartments: Dispatch<SetStateAction<Department[]>>
    selectedCategories: Category[]
    setSelectedCategories: Dispatch<SetStateAction<Category[]>>
    textFeedbacks: TextFeedbackWithCreatedAt[]
}

export const DashboardSidebar = ({
    selectedCategory,
    textFeedbacks,
    selectedDepartments,
    setSelectedDepartments,
    selectedCategories,
    setSelectedCategories,
}: Props) => {
    const [activeView, setActiveView] = useState<'comments' | 'chat'>('comments')

    return (
        <Sidebar className='mb-4 overflow-y-auto bg-gradient-to-br from-gray-900 to-black p-4 md:bg-none md:p-6'>
            <SidebarHeader className='mb-4 border-b border-gray-700 pb-4'>
                <Button
                    variant={activeView === 'comments' ? 'default' : 'outline'}
                    className={`flex-1 ${
                        activeView === 'comments'
                            ? 'bg-blue-600 text-gray-200 hover:bg-blue-700 hover:text-white'
                            : 'text-gray-400 hover:text-white'
                    }`}
                    onClick={() => setActiveView('comments')}
                >
                    <MessageSquare className='mr-2 h-5 w-5' />
                    Comentarios
                </Button>
                <Button
                    variant={activeView === 'chat' ? 'default' : 'outline'}
                    className={`flex-1 ${
                        activeView === 'chat'
                            ? 'bg-blue-600 text-gray-200 hover:bg-blue-700 hover:text-white'
                            : 'text-gray-400 hover:text-white'
                    }`}
                    onClick={() => setActiveView('chat')}
                >
                    <Bot className='mr-2 h-5 w-5' />
                    Chat
                </Button>
            </SidebarHeader>
            <SidebarContent>
                {activeView === 'comments' ? (
                    <CommentsSidebar
                        selectedCategory={selectedCategory}
                        selectedCategories={selectedCategories}
                        selectedDepartments={selectedDepartments}
                        setSelectedCategories={setSelectedCategories}
                        setSelectedDepartments={setSelectedDepartments}
                        textFeedbacks={textFeedbacks as WithCreatedAt<TextFeedback>[]}
                    />
                ) : (
                    <AIChat />
                )}
            </SidebarContent>
        </Sidebar>
    )
}
