import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { motion } from 'framer-motion'

import { BaseFormStepProps } from '@/pages/form/types'
import { useEventFeedbackAnswer } from '@/pages/form/useEventFeedback.ts'
import { Button } from '@/shared/ui/button'
import { Input } from '@/shared/ui/input'
import { Label } from '@/shared/ui/label.tsx'
import { RadioGroup, RadioGroupItem } from '@/shared/ui/radio-group.tsx'
import { RadioQuestion } from '@monorepo/functions/src/types/feedback'

import { SkipButton } from './SkipButton'

const buttonVariants = {
    initial: { scale: 1, opacity: 0, x: -20 },
    pressed: { scale: 0.95 },
}

const FormButton = motion(Button)

export const RadioGroupFormStep = ({ question, onNextStep }: BaseFormStepProps<RadioQuestion>) => {
    const { t } = useTranslation()
    const { setAnswer, answer } = useEventFeedbackAnswer(question)
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

    const additionalField = question.additionalFields?.find(({ triggeredBy }) => triggeredBy === answer?.value) ?? null

    const handleValueChange = (value: string) => {
        const isAdditionalFieldOptionSelected = !!question.additionalFields?.find(
            ({ triggeredBy }) => triggeredBy === value,
        )

        setAnswer({
            type: 'RADIO',
            value,
            departments: question.departments,
            categories: question.categories,
        })

        if (isAdditionalFieldOptionSelected) return

        timeoutRef.current = setTimeout(() => {
            onNextStep()
        }, 250)
    }

    const handleAdditionalFieldValueChange = (newValue: string) => {
        setAnswer({
            type: 'RADIO',
            value: answer!.value,
            departments: question.departments,
            categories: question.categories,
            additionalFieldValue: newValue,
        })
    }

    useEffect(
        () => () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current)
        },
        [],
    )

    return (
        <>
            <h2 className='text-center text-xl font-medium text-primary'>{question.title}</h2>
            <div className='mt-6 flex w-fit flex-col justify-center justify-self-center'>
                <RadioGroup defaultValue={answer?.value} onValueChange={handleValueChange}>
                    {question.options.map(option => (
                        <div className='flex items-center' key={option.value}>
                            <RadioGroupItem
                                value={option.value}
                                checked={option.value === answer?.value}
                                id={option.value}
                                className='border-primary/20 text-primary'
                            />
                            <Label className='ml-2 text-lg text-primary' htmlFor={option.value}>
                                {option.label}
                            </Label>
                        </div>
                    ))}
                </RadioGroup>
                {additionalField && (
                    <Input
                        placeholder={additionalField.label}
                        value={answer?.additionalFieldValue ?? ''}
                        onChange={e => handleAdditionalFieldValueChange(e.target.value)}
                        className='mb-1 mt-2 w-full border-primary/20 bg-background text-primary placeholder:text-primary/50'
                    />
                )}
                <div className='mt-2 flex justify-end gap-2'>
                    <SkipButton onSkip={onNextStep} />
                    {additionalField && (
                        <FormButton
                            type='button'
                            variants={buttonVariants}
                            whileTap='pressed'
                            size='default'
                            onClick={onNextStep}
                            initial='initial'
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5 }}
                            className='-bottom-14 right-0 w-fit self-end bg-primary text-primary-foreground hover:bg-primary/90'
                        >
                            {t('form.common.next')}
                        </FormButton>
                    )}
                </div>
            </div>
        </>
    )
}
