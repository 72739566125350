import { cn } from '@/shared/lib/styles'

interface CountIndicatorProps {
    count: number
    isSelected?: boolean
    className?: string
    showOnlyIfPositive?: boolean
}

export const CountIndicator = ({ count, isSelected, className, showOnlyIfPositive = true }: CountIndicatorProps) => {
    if (showOnlyIfPositive && count <= 0) return null

    return (
        <span
            className={cn(
                'flex h-6 w-6 items-center justify-center rounded-full text-sm',
                isSelected ? 'bg-white bg-opacity-20' : 'bg-gray-700',
                className,
            )}
        >
            {count}
        </span>
    )
}
