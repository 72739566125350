import { ClickableLegendProps } from '@/shared/ui/chart'
import { RadioFeedback } from '@monorepo/functions/src/types/feedback'

import { InsightsCard } from './InsightsCard'
import { InsightsPieChart } from './InsightsPieChart'
import { getSingleSelectChartData } from './getSingleSelectChartData'

interface Props {
    feedbacks: RadioFeedback[]
}

export const RadioInsights = ({ feedbacks }: Props) => {
    const feedbackByTitle = feedbacks.reduce(
        (acc, feedback) => {
            const title = feedback.question.title

            if (!acc[title]) acc[title] = []
            acc[title].push(feedback)
            return acc
        },
        {} as Record<string, RadioFeedback[]>,
    )

    return Object.entries(feedbackByTitle).map(([title, filteredFeedbacks]) => {
        const radioChartData = getSingleSelectChartData(title, filteredFeedbacks)

        const additionalFieldTrigger = filteredFeedbacks[0].question.additionalFields?.[0].triggeredBy
        const optionWithAdditionalField = filteredFeedbacks[0].question.options.find(
            option => option.value === additionalFieldTrigger,
        )

        const additionalAnswers = filteredFeedbacks.reduce<string[]>((accum, curr) => {
            const additionalAnswer = curr.answer.additionalFieldValue
            if (additionalAnswer) accum.push(additionalAnswer)
            return accum
        }, [])

        const clickableLegendProps: ClickableLegendProps = optionWithAdditionalField
            ? {
                  hoverableLegendLabel: optionWithAdditionalField.label,
                  legendTooltipContent: (
                      <div className='max-w-60'>
                          <p className='mb-2 text-sm'>Respuestas de clientes</p>
                          <ul className='ml-2 list-disc'>
                              {additionalAnswers.map(additionalAnswer => (
                                  <li>{additionalAnswer}</li>
                              ))}
                          </ul>
                      </div>
                  ),
              }
            : {}

        return (
            <InsightsCard key={title} questionTitle={title}>
                <InsightsPieChart data={radioChartData} {...clickableLegendProps} />
            </InsightsCard>
        )
    })
}
