import { useRef } from 'react'

export const useScrollToBottom = <T extends HTMLElement = HTMLDivElement>() => {
    const ref = useRef<T>(null)

    const scrollToBottom = () => {
        if (!ref.current) return

        const { scrollHeight, clientHeight } = ref.current
        ref.current.scrollTop = scrollHeight - clientHeight
    }

    return { ref, scrollToBottom }
}
