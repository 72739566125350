import { FirestoreDataConverter, collection, getDocs, orderBy, query } from 'firebase/firestore'

import { firestore } from '@/shared/firebase/firebase'
import { ChatMessage } from '@monorepo/functions/src/types/chat'

const getChatMessagesCollection = (eventId: string, chatId: string) =>
    collection(firestore, `events/${eventId}/chats/${chatId}/messages`)

const chatMessageConverter: FirestoreDataConverter<ChatMessage> = {
    toFirestore: chatMessage => chatMessage,
    fromFirestore: snapshot => snapshot.data() as ChatMessage,
}

export const getChatMessages = async (eventId: string, chatId: string): Promise<ChatMessage[]> => {
    const messages = await getDocs(
        query(
            getChatMessagesCollection(eventId, chatId).withConverter(chatMessageConverter),
            orderBy('createdAt', 'asc'),
        ),
    )

    if (messages.empty) return []
    return messages.docs.map(doc => doc.data())
}
