import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFillWaterAnimation } from '@/pages/form/components/FillWaterAnimationContext.tsx'
import { SkipButton } from '@/pages/form/components/SkipButton'
import { BaseFormStepProps } from '@/pages/form/types'
import { useEventFeedbackAnswer } from '@/pages/form/useEventFeedback.ts'
import { newFeedbackQuestionAnswered } from '@/shared/api/clients.ts'
import { useWaterAnimationEnabled } from '@/shared/lib/hooks/useWaterAnimationEnabled'
import { Button } from '@/shared/ui/button.tsx'
import { Textarea } from '@/shared/ui/textarea.tsx'
import { useEventId } from '@/shared/useEventId.ts'
import { TextQuestion } from '@monorepo/functions/src/types/feedback'

export const FreeTextFormStep = ({ question, onNextStep }: BaseFormStepProps<TextQuestion>) => {
    const eventId = useEventId()
    const { answer } = useEventFeedbackAnswer(question)
    const { createDrop, removeWater, resetCanvas, showCanvas, hideCanvas } = useFillWaterAnimation()
    const [localAnswer, setLocalAnswer] = useState(answer?.value ?? '')
    const { t } = useTranslation()
    const isWaterAnimationEnabled = useWaterAnimationEnabled()

    useEffect(() => {
        if (isWaterAnimationEnabled) {
            showCanvas()
            return () => hideCanvas()
        }
        hideCanvas()
    }, [showCanvas, hideCanvas, isWaterAnimationEnabled])

    const handleNextClick = async () => {
        newFeedbackQuestionAnswered({
            eventId,
            questionTitle: question.title,
            newAnswer: localAnswer,
        })
        onNextStep()
        resetCanvas()
    }

    const handleChange = (newValue: string) => {
        const oldLength = localAnswer.length
        const newLength = newValue.length

        setLocalAnswer(newValue)

        if (isWaterAnimationEnabled) {
            if (newLength > oldLength) {
                createDrop()
            } else if (newLength < oldLength) {
                removeWater(oldLength - newLength)
            }
        }
    }
    return (
        <div className='space-y-6'>
            <h2 className='text-center text-xl font-medium text-primary'>{question.title}</h2>
            <div className='flex flex-col items-center gap-4'>
                <Textarea
                    name={question.title}
                    className='h-32 w-full border-primary/20 bg-background text-primary placeholder:text-primary/50 md:w-3/4'
                    placeholder={t('form.freeText.placeholder', 'Write your answer here...')}
                    onChange={event => handleChange(event.target.value)}
                    value={localAnswer ?? ''}
                />
                <div className='flex w-full justify-end gap-2 md:w-3/4'>
                    <SkipButton onSkip={onNextStep} />
                    <Button
                        onClick={handleNextClick}
                        disabled={!localAnswer}
                        className='bg-primary text-primary-foreground hover:bg-primary/90'
                    >
                        {t('form.common.next', 'Next')}
                    </Button>
                </div>
            </div>
        </div>
    )
}
