import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { motion } from 'framer-motion'

import { SkipButton } from '@/pages/form/components/SkipButton'
import { BaseFormStepProps } from '@/pages/form/types'
import { useEventFeedbackAnswer } from '@/pages/form/useEventFeedback.ts'
import { Button } from '@/shared/ui/button'
import { Input } from '@/shared/ui/input'
import { Slider } from '@/shared/ui/slider.tsx'
import { LikertQuestion } from '@monorepo/functions/src/types/feedback'

const buttonVariants = {
    initial: { scale: 1, opacity: 0, x: -20 },
    pressed: { scale: 0.95 },
}

const FormButton = motion(Button)

type ExtractArrayType<T> = T extends Array<infer U> ? U : never

export const SliderFormStep = ({ question, onNextStep }: BaseFormStepProps<LikertQuestion>) => {
    const { t } = useTranslation()
    const { answer, setAnswer } = useEventFeedbackAnswer(question)
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
    const [additionalField, setAdditionalField] = useState<ExtractArrayType<LikertQuestion['additionalFields']> | null>(
        question.additionalFields?.find(({ triggeredBy }) => answer?.value && answer?.value <= triggeredBy) ?? null,
    )

    const onValueChange = ([value]: [number]) => {
        setAnswer({
            type: 'SLIDER',
            value,
            departments: question.departments,
            categories: question.categories,
        })
    }

    const onValueCommmit = ([value]: [number]) => {
        const newAdditionalField = question.additionalFields?.find(({ triggeredBy }) => value <= triggeredBy) ?? null
        setAdditionalField(newAdditionalField)

        if (newAdditionalField) return

        timeoutRef.current = setTimeout(() => {
            onNextStep()
        }, 250)
    }

    const handleAdditionalFieldValueChange = (newValue: string) => {
        setAnswer({
            type: 'SLIDER',
            value: answer!.value,
            departments: question.departments,
            categories: question.categories,
            additionalFieldValue: newValue,
        })
    }

    return (
        <div className='flex flex-col items-center space-y-6'>
            <div className='space-y-2 text-center'>
                <h2 className='text-xl font-medium text-primary'>{question.title}</h2>
                {question.explanation && <p className='text-sm text-muted-foreground'>{question.explanation}</p>}
            </div>
            <div className='w-80 space-y-4'>
                <Slider
                    min={1}
                    max={5}
                    step={1}
                    name={question.title}
                    className='w-full'
                    onValueChange={onValueChange}
                    onValueCommit={onValueCommmit}
                    value={answer ? [answer?.value] : [1]}
                />
                <div className='flex justify-between px-2 text-xs text-primary'>
                    {[1, 2, 3, 4, 5].map(value => (
                        <span key={value} className='relative w-6 text-center'>
                            {value}
                            {question.labels && (value === 1 || value === 5) && (
                                <div className='absolute left-1/2 mt-1 w-max -translate-x-1/2 text-muted-foreground'>
                                    {value === 1 ? question.labels.min : question.labels.max}
                                </div>
                            )}
                        </span>
                    ))}
                </div>
            </div>
            {additionalField && (
                <Input
                    placeholder={additionalField.label}
                    value={answer?.additionalFieldValue ?? ''}
                    onChange={e => handleAdditionalFieldValueChange(e.target.value)}
                    className='mb-1 mt-2 w-80 border-primary/20 bg-background text-primary placeholder:text-primary/50'
                />
            )}
            <div className='mt-2 flex justify-end gap-2'>
                <SkipButton onSkip={onNextStep} />
                {additionalField && (
                    <FormButton
                        type='button'
                        variants={buttonVariants}
                        whileTap='pressed'
                        size='default'
                        onClick={onNextStep}
                        initial='initial'
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                        className='w-fit bg-primary text-primary-foreground hover:bg-primary/90'
                    >
                        {t('form.common.next')}
                    </FormButton>
                )}
            </div>
        </div>
    )
}
