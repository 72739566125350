import { Dispatch, SetStateAction, useMemo, useState } from 'react'

import { ChevronDown, Search } from 'lucide-react'

import { Category, Department, TextFeedback, WithCreatedAt } from '@monorepo/functions/src/types/feedback'

import { categories, departments } from '../../model/constants'
import { getCategoryCount, getDepartmentCount } from '../../model/feedbackCounts'
import { CommentCard } from '../CommentCard'
import { CountIndicator } from '../CountIndicator'
import { getCategoryColor } from '../colors'
import { categoryIcons, departmentIcons } from '../icons'

type TextFeedbackWithCreatedAt = WithCreatedAt<TextFeedback>

interface Props {
    selectedCategory: Category
    selectedDepartments: Department[]
    setSelectedDepartments: Dispatch<SetStateAction<Department[]>>
    selectedCategories: Category[]
    setSelectedCategories: Dispatch<SetStateAction<Category[]>>
    textFeedbacks: TextFeedbackWithCreatedAt[]
}

export const CommentsSidebar = ({
    selectedCategory,
    textFeedbacks,
    selectedDepartments,
    setSelectedDepartments,
    selectedCategories,
    setSelectedCategories,
}: Props) => {
    const [searchQuery, setSearchQuery] = useState('')
    const [showCategoryDropdown, setShowCategoryDropdown] = useState(false)
    const [showDepartmentDropdown, setShowDepartmentDropdown] = useState(false)

    const filteredComments = useMemo(() => {
        return textFeedbacks.filter(comment => {
            const matchesCategory =
                selectedCategories.length === 0 ||
                comment.answer.categories.some(cat => selectedCategories.includes(cat))
            const matchesDepartment =
                selectedDepartments.length === 0 ||
                comment.answer.departments.some(dept => selectedDepartments.includes(dept))
            const matchesSearch = comment.answer.value.toLowerCase().includes(searchQuery.toLowerCase())
            return matchesCategory && matchesDepartment && matchesSearch
        })
    }, [textFeedbacks, selectedCategories, selectedDepartments, searchQuery])

    const toggleCategory = (category: Category) => {
        setSelectedCategories(prev =>
            prev.includes(category) ? prev.filter(c => c !== category) : [...prev, category],
        )
    }

    const toggleDepartment = (dept: Department) => {
        setSelectedDepartments(prev => (prev.includes(dept) ? prev.filter(d => d !== dept) : [...prev, dept]))
    }

    return (
        <>
            <div className='relative mb-6 mt-2'>
                <input
                    type='text'
                    placeholder='Buscar comentarios...'
                    className='w-full rounded-lg bg-gray-800 bg-opacity-50 p-3 pl-10 shadow-inner backdrop-blur-sm'
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                />
                <Search className='absolute left-3 top-3 text-gray-400' />
            </div>
            <div className='mb-6 flex space-x-4'>
                <div className='relative w-1/2'>
                    <button
                        className='flex w-full items-center justify-between rounded-lg bg-gray-800 bg-opacity-50 p-3 shadow-lg backdrop-blur-sm transition-colors duration-300 hover:bg-gray-700'
                        onClick={() => setShowCategoryDropdown(!showCategoryDropdown)}
                    >
                        <span>Categorías</span>
                        <div className='flex items-center gap-2'>
                            <CountIndicator count={selectedCategories.length} showOnlyIfPositive={true} />
                            <ChevronDown />
                        </div>
                    </button>
                    {showCategoryDropdown && (
                        <div className='absolute left-0 top-full z-10 mt-1 w-full rounded-lg bg-gray-800 bg-opacity-90 shadow-xl backdrop-blur-sm'>
                            {categories.map(category => {
                                const count = getCategoryCount(textFeedbacks, category)
                                return (
                                    <div
                                        key={category}
                                        className={`flex w-full cursor-pointer flex-row items-center justify-between gap-3 p-2 transition-all duration-300 ${
                                            selectedCategories.includes(category)
                                                ? `bg-gradient-to-r ${getCategoryColor(category)}`
                                                : 'hover:bg-gray-700'
                                        }`}
                                        onClick={() => toggleCategory(category)}
                                    >
                                        <div className='flex items-center gap-3'>
                                            <span>{categoryIcons[category]}</span>
                                            {category}
                                        </div>
                                        <CountIndicator
                                            count={count}
                                            isSelected={selectedCategories.includes(category)}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>
                <div className='relative w-1/2'>
                    <button
                        className='flex w-full items-center justify-between rounded-lg bg-gray-800 bg-opacity-50 p-3 shadow-lg backdrop-blur-sm transition-colors duration-300 hover:bg-gray-700'
                        onClick={() => setShowDepartmentDropdown(!showDepartmentDropdown)}
                    >
                        <span>Departamentos</span>
                        <div className='flex items-center gap-2'>
                            <CountIndicator count={selectedDepartments.length} showOnlyIfPositive={true} />
                            <ChevronDown />
                        </div>
                    </button>
                    {showDepartmentDropdown && (
                        <div className='absolute left-0 top-full z-10 mt-1 w-full rounded-lg bg-gray-800 bg-opacity-90 shadow-xl backdrop-blur-sm'>
                            {departments.map(dept => {
                                const count = getDepartmentCount(textFeedbacks, dept)

                                return (
                                    <div
                                        key={dept}
                                        className={`flex w-full cursor-pointer flex-row items-center justify-between gap-2 p-2 transition-all duration-300 ${
                                            selectedDepartments.includes(dept)
                                                ? `bg-gradient-to-r ${getCategoryColor(selectedCategory)}`
                                                : 'hover:bg-gray-700'
                                        }`}
                                        onClick={() => toggleDepartment(dept)}
                                    >
                                        <div className='flex items-center gap-2'>
                                            <span className='mr-2'>{departmentIcons[dept]}</span>
                                            {dept}
                                        </div>
                                        <CountIndicator count={count} isSelected={selectedDepartments.includes(dept)} />
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>
            </div>
            <div className='space-y-4'>
                {filteredComments
                    .sort((a, b) => b.answer.value.length - a.answer.value.length)
                    .map((comment, i) => (
                        <CommentCard key={i + comment.question.title} comment={comment} />
                    ))}
            </div>
        </>
    )
}
