import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { signOut } from 'firebase/auth'
import { Loader2, LogOut } from 'lucide-react'

import { getUserEvents } from '@/shared/api/clients'
import { useAuth } from '@/shared/auth'
import { auth } from '@/shared/firebase/firebase'
import { Button } from '@/shared/ui/button'
import { EventDetails } from '@monorepo/functions/src/types/GetUserEvents'

import { EventsGrid } from './EventsGrid'

export const EventSelectorPage = () => {
    const [events, setEvents] = useState<EventDetails[]>(() => {
        const cachedEvents = localStorage.getItem('events')
        return cachedEvents ? JSON.parse(cachedEvents) : []
    })
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)
    const { user } = useAuth()
    const navigate = useNavigate()

    const fetchEvents = useCallback(async () => {
        if (!user) return

        try {
            setIsLoading(true)
            setError(null)
            const result = await getUserEvents()
            const fetchedEvents = result.data.events
            setEvents(fetchedEvents)
            localStorage.setItem('events', JSON.stringify(fetchedEvents))
        } catch (error) {
            console.error('Error fetching events:', error)
            setError('Error al cargar los tableros. Por favor, inténtalo de nuevo más tarde.')
        } finally {
            setIsLoading(false)
        }
    }, [user])

    useEffect(() => {
        fetchEvents()
    }, [fetchEvents])

    const handleLogout = async () => {
        await signOut(auth)
        navigate('/login')
    }

    const handleEventUpdate = (updatedEvent: EventDetails) => {
        // First update the event in memory
        const updatedEvents = events.map(event => (event.id === updatedEvent.id ? updatedEvent : event))
        setEvents(updatedEvents)
        localStorage.setItem('events', JSON.stringify(updatedEvents))

        // Then fetch fresh data
        fetchEvents()
    }

    if (isLoading && events.length === 0) {
        return (
            <div className='flex h-screen items-center justify-center bg-gradient-to-br from-gray-900 to-black'>
                <div className='flex items-center gap-3 text-xl text-white'>
                    <Loader2 className='h-6 w-6 animate-spin' />
                    Cargando...
                </div>
            </div>
        )
    }

    return (
        <div className='min-h-screen bg-gradient-to-br from-gray-900 to-black p-8'>
            <div className='mx-auto max-w-7xl'>
                <div className='mb-8 flex items-center justify-between'>
                    <div>
                        <img className='w-80' src='/boothbits-logo.svg' alt='BoothBits logo' />
                        <h1 className='mt-4 bg-gradient-to-r from-blue-400 to-cyan-600 bg-clip-text text-3xl font-bold text-transparent'>
                            Seleccionar Evento
                        </h1>
                    </div>
                    <Button
                        variant='ghost'
                        onClick={handleLogout}
                        className='flex items-center gap-2 text-white hover:bg-gray-800'
                    >
                        <LogOut className='h-4 w-4' />
                        Cerrar Sesión
                    </Button>
                </div>

                {error && (
                    <div className='mb-8 rounded-lg border border-red-500 bg-red-500/10 p-4 text-red-500'>
                        <p>{error}</p>
                    </div>
                )}

                {isLoading && events.length > 0 && (
                    <div className='mb-4 flex items-center gap-2 text-white'>
                        <Loader2 className='h-4 w-4 animate-spin' />
                        Actualizando eventos...
                    </div>
                )}

                <EventsGrid events={events} onEventUpdate={handleEventUpdate} />
            </div>
        </div>
    )
}
