import { useEffect } from 'react'

import { useQuery } from '@/app/navigation/useQuery'
import { useEventFeedbackAnswer } from '@/pages/form/useEventFeedback'
import { RadioQuestion } from '@monorepo/functions/src/types/feedback'

const experienceLengthQuestion: RadioQuestion = {
    type: 'RADIO',
    title: 'Which experience did you choose?',
    departments: ['Otros'],
    categories: ['Otros'],
    options: [
        { label: '6', value: '6' },
        { label: '12', value: '12' },
        { label: '20', value: '20' },
        { label: '32', value: '32' },
    ],
}

export const FormSummary: React.FC = () => {
    const expLength = useQuery('expLength')
    const postRedirectUrl = useQuery('postRedirectUrl')
    const { setAnswer } = useEventFeedbackAnswer(experienceLengthQuestion)

    useEffect(() => {
        if (expLength && postRedirectUrl) {
            setAnswer(
                {
                    type: 'RADIO',
                    value: expLength,
                    departments: experienceLengthQuestion.departments,
                    categories: experienceLengthQuestion.categories,
                },
                () => {
                    window.location.href = decodeURIComponent(postRedirectUrl)
                },
            )
        }
    }, [expLength, postRedirectUrl])

    return null
}
