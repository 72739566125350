import { getEventChatAnswer } from '@/shared/api/clients'
import { reauthenticateUser } from '@/shared/auth'

export const sendEventChatMessage = async (
    eventId: string,
    message: string,
    chatId: string,
    onChunk: (chunk: string) => void,
): Promise<void> => {
    const response = await getEventChatAnswer({ eventId, message, chatId })

    if (!response.ok) {
        if (response.error.code === 'AUTH_ERROR') return reauthenticateUser()
        throw new Error('Network response was not ok')
    }

    const reader = response.response.body?.getReader()
    if (!reader) throw new Error('Unable to read response')

    const decoder = new TextDecoder()

    // eslint-disable-next-line no-constant-condition
    while (true) {
        const { done, value } = await reader.read()
        if (done) break

        const chunk = decoder.decode(value)
        const lines = chunk.split('\n')

        for (const line of lines) {
            if (line.startsWith('data: ')) {
                if (line.includes('[DONE]')) return onChunk('[DONE]')

                const data = JSON.parse(line.slice(6))
                onChunk(data.content)
            }
        }
    }
}
