import { Timestamp } from 'firebase/firestore'

import { useUser } from '@/shared/auth.tsx'
import { useDocument } from '@/shared/firebase/hooks/useDocument.tsx'
import { useEventId } from '@/shared/useEventId.ts'
import {
    Answer,
    ExtractAnswerFromQuestion,
    PersonFeedback,
    PersonFeedbacks,
    Question,
} from '@monorepo/functions/src/types/feedback'

export const useEventFeedback = () => {
    const eventId = useEventId()
    const { uid } = useUser()
    const {
        document: eventFeedback,
        setDocument: setEventFeedback,
        setDocumentSync: setEventFeedbackSync,
    } = useDocument<PersonFeedbacks>({
        collectionName: `/events/${eventId ?? 'demo'}/feedback`,
        id: uid,
        defaultValue: {
            id: uid,
            createdAt: Timestamp.now(),
            feedbacks: [],
        },
    })

    return { eventFeedback, setEventFeedback, setEventFeedbackSync }
}

type UseEventFeedbackAnswerResponse<T extends Question> = {
    answer?: ExtractAnswerFromQuestion<T>
    setAnswer: (answer: ExtractAnswerFromQuestion<T>, onAnswerPersisted?: () => void) => void
}

export const useEventFeedbackAnswer = <T extends Question>(question: T): UseEventFeedbackAnswerResponse<T> => {
    const { eventFeedback, setEventFeedback } = useEventFeedback()

    const personFeedback = eventFeedback.feedbacks.find(a => a.question.title === question.title)
    const answer = personFeedback?.answer as ExtractAnswerFromQuestion<T> | undefined

    const setAnswer = (answer: Answer, onAnswerPersisted?: () => void) => {
        const newPersonFeedback = { question, ...personFeedback, answer } as Extract<
            PersonFeedback,
            { question: { type: T['type'] } }
        >
        const newPersonFeedbacks = eventFeedback.feedbacks.filter(a => a.question.title !== question.title)

        setEventFeedback({ ...eventFeedback, feedbacks: [...newPersonFeedbacks, newPersonFeedback] }, onAnswerPersisted)
    }

    return { answer, setAnswer }
}
