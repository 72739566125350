import { auth } from '../firebase/firebase'
import { ErrorCodes } from './errors'

type ApiResponse = {
    ok: true
    response: Response
}

type ApiError = {
    ok: false
    error: {
        code: ErrorCodes
    }
}

const baseURL =
    window.location.hostname === 'localhost'
        ? 'http://127.0.0.1:5001/booth-bits/us-central1'
        : 'https://us-central1-booth-bits.cloudfunctions.net'

export const fetcher = async <TBody = undefined>({
    endpoint,
    method,
    headers,
    body,
}: {
    endpoint: string
    method: RequestInit['method']
    headers?: HeadersInit
    body?: TBody
}): Promise<ApiResponse | ApiError> => {
    try {
        const token = await auth.currentUser?.getIdToken()

        const response = await fetch(`${baseURL}${endpoint}`, {
            method,
            headers: {
                'Content-Type': 'application/json',
                ...(token && { Authorization: 'Bearer ' + token }),
                ...headers,
            },
            ...(body && { body: JSON.stringify(body) }),
        })

        if (!response.ok) {
            if (response.status === 401) return { ok: false, error: { code: 'AUTH_ERROR' } }
            throw new Error() // force generic error
        }

        return { ok: true, response }
    } catch (error) {
        return { ok: false, error: { code: 'GENERIC_ERROR' } }
    }
}
